var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white-bg green-border-box outer-box" }, [
    _c("div", { staticClass: "inner-box" }, [
      _c(
        "label",
        {
          staticClass: "img-contain",
          attrs: { for: "attr-image-" + _vm.task.id }
        },
        [
          _c("img", {
            directives: [
              {
                name: "square-img",
                rawName: "v-square-img",
                value: { ratio: 1.5 },
                expression: "{ ratio: 1.5 }"
              }
            ],
            staticClass: "vertical-center-transform",
            attrs: { src: _vm.task.image + "?w=300&q=100&fit=crop" }
          }),
          _c("div", { staticClass: "img-overlay" }, [
            _c("span", { staticClass: "vertical-center-transform task-name" }, [
              _vm._v(_vm._s(_vm.task.name))
            ])
          ])
        ]
      ),
      _vm.task.pickls_count == 0
        ? _c("div", { staticClass: "text-left float-left" }, [
            _c(
              "button",
              {
                staticClass: "btn-negative",
                attrs: {
                  "data-target": "#deletetaskpopup",
                  "data-toggle": "modal",
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("loadTaskDetails", _vm.task)
                  }
                }
              },
              [_vm._v("DELETE")]
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn-positive",
            attrs: {
              "data-target": "#edit-task-popup",
              "data-toggle": "modal",
              type: "button"
            },
            on: {
              click: function($event) {
                return _vm.$emit("loadTaskDetails", _vm.task)
              }
            }
          },
          [_vm._v("EDIT")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }