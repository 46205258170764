










































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeactivateBrandPopup extends Vue {
  @Prop() popupId!: string
  @Prop() taskDetails!: any

  deleteLocation () {
    this.$http
      .delete('tasks/' + this.taskDetails.id, {
        headers: {
          'user-type': 'sadmin'
        }
      })
      .then((response) => {
        this.$emit('refreshTaskLocationsList')
        let DeleteLocationCloseButtonPopup: HTMLElement = this.$refs.DeleteLocationCloseButtonPopup as HTMLElement
        this.$emit('TaskDeleted')
        DeleteLocationCloseButtonPopup.click()
      })
  }
}
