var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box in-padd white-bg" },
    [
      _c("div", { staticClass: "row", staticStyle: { padding: "1%" } }, [
        _c("div", { staticClass: "filters" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.query,
                expression: "query"
              }
            ],
            staticClass: "btn-select",
            attrs: { type: "text", placeholder: "Search Task" },
            domProps: { value: _vm.query },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.query = $event.target.value
              }
            }
          })
        ])
      ]),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.tasks, function(task, index) {
          return _c(
            "div",
            { key: task.id, staticClass: "col-xs-4 col-sm-2 item-list" },
            [
              _c("TaskItem", {
                attrs: { index: index, task: task },
                on: {
                  changeStatus: _vm.changeStatus,
                  loadTaskDetails: _vm.setTaskDetails
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "text-center" }, [
        _vm.hasLoadMore
          ? _c(
              "button",
              {
                staticClass: "btn-positive",
                attrs: { disabled: _vm.busy },
                on: { click: _vm.loadMore }
              },
              [_vm._v("Load more")]
            )
          : _vm._e()
      ]),
      !_vm.busy && (!_vm.tasks || _vm.tasks.length == 0)
        ? _c("h3", { staticClass: "text-center mt20" }, [
            _vm._v("\n      No Task found.\n    ")
          ])
        : _vm._e(),
      _c("TaskPopup", {
        attrs: {
          popupId: "add-task-popup",
          taskDetails: _vm.taskDetails,
          editTaskFlag: false
        },
        on: { updateTasksList: _vm.getTasks }
      }),
      _c("TaskPopup", {
        attrs: {
          popupId: "edit-task-popup",
          taskDetails: _vm.taskDetails,
          editTaskFlag: true
        },
        on: { updateTasksList: _vm.getTasks }
      }),
      _c("DeleteTaskPopup", {
        attrs: { taskDetails: _vm.taskDetails, popupId: "deletetaskpopup" },
        on: { TaskDeleted: _vm.taskDeleted }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }