var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { "data-vv-scope": "add-task" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addTask($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("h3", [_vm._v("Task Name")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskDetails.name,
                          expression: "taskDetails.name"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("add-task.name")
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Task Name",
                        name: "name"
                      },
                      domProps: { value: _vm.taskDetails.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.taskDetails, "name", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("h3", [_vm._v("Order")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskDetails.order,
                          expression: "taskDetails.order"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("add-task.order")
                      },
                      attrs: {
                        type: "text",
                        placeholder: "Task Name",
                        name: "order"
                      },
                      domProps: { value: _vm.taskDetails.order },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.taskDetails,
                            "order",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("h3", [_vm._v("Add Task Image")]),
                    _c(
                      "div",
                      { staticClass: "image-upload" },
                      [
                        _c("croppa", {
                          attrs: {
                            width: 400,
                            height: 500,
                            placeholder: "Choose a task image",
                            "initial-size": "contain",
                            "placeholder-font-size": 16,
                            "show-remove-button": false,
                            "initial-image": _vm.currentImage
                          },
                          on: {
                            "file-choose": _vm.CropEnable,
                            "image-remove": _vm.cropDisable,
                            move: _vm.CropEnable,
                            zoom: _vm.CropEnable,
                            "new-image-drawn": _vm.generateImage
                          },
                          model: {
                            value: _vm.myCroppa,
                            callback: function($$v) {
                              _vm.myCroppa = $$v
                            },
                            expression: "myCroppa"
                          }
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.taskDetails.image,
                              expression: "taskDetails.image"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: { type: "hidden", name: "image" },
                          domProps: { value: _vm.taskDetails.image },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.taskDetails,
                                "image",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("br"),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("add-task.image"),
                                expression: "errors.has('add-task.image')"
                              }
                            ],
                            staticClass: "error-msg"
                          },
                          [_vm._v(_vm._s(_vm.errors.first("add-task.image")))]
                        ),
                        _c("br"),
                        _vm.taskDetails.image
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-negative",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.myCroppa.remove()
                                  }
                                }
                              },
                              [_vm._v("Remove")]
                            )
                          : _c(
                              "button",
                              {
                                staticClass: "btn-positive uploadButton",
                                attrs: {
                                  type: "button",
                                  disabled: !_vm.imageSelected
                                },
                                on: { click: _vm.generateImage }
                              },
                              [_vm._v("Crop")]
                            )
                      ],
                      1
                    )
                  ])
                ]),
                _c("input", {
                  staticClass: "btn-lg-green",
                  attrs: { type: "submit", disabled: _vm.submittingForm },
                  domProps: {
                    value: _vm.editTaskFlag ? "UPDATE" : "ADD" + " TASK"
                  }
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("img", { attrs: { src: "/img/Alerts/plusbig_icon.png" } }),
      _vm._v(" Task")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }