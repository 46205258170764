





























import TaskItem from '@/components/SAdmin/TaskItem.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import TaskPopup from '@/components/SAdmin/TaskPopup.vue'
import DeleteTaskPopup from '@/components/SAdmin/DeleteTaskPopup.vue'

@Component({
  components: {
    TaskItem,
    TaskPopup,
    DeleteTaskPopup
  }
})
export default class Tasks extends Vue {
  public query = ''
  public limit = 100
  public offset = 0
  public brandId = 0
  public actionTask: any = null
  public tasks: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public taskDetails: {id: any, name: string, order: number, description:string, image: string} = {
    id: '',
    name: '',
    order: 99,
    description: '',
    image: ''
  }
  public productDetails: object = {}
  public serviceEnabled: any = ''

  getTasks (clear = true) {
    this.busy = true
    this.$store.dispatch('getTasksList', { query: this.query, limit: this.limit, offset: this.offset, service_enabled: this.serviceEnabled }).then((response) => {
      if (clear) this.tasks = []
      this.tasks.push(...response.response.body.data)
      this.totalCount = response.response.body.count
      this.busy = false
    }, (response) => {
      this.busy = false
    })
  }

  changeStatus (task: any, index: number) {
    this.$store.dispatch('toggleTaskStatus', task).then((response) => {
      this.$notify({ type: 'success', text: response.response.body.message })
      this.getTasks()
    }, (response) => {
      this.busy = false
    })
  }

  loadMore () {
    this.$data.offset += this.$data.limit
    this.getTasks(false)
  }
  storeDeleted () {
    this.getTasks()
  }

  setTaskDetails (task: any) {
    this.taskDetails = { 'id': task.id, 'name': task.name, 'order': task.order, 'image': task.image, 'description': task.description }
  }

  taskDeleted () {
    this.getTasks()
  }

  @Watch('query')
  onSearch () {
    Vue.common.delay(() => {
      this.offset = 0
      this.getTasks()
    }, 500)()
  }

  created () {
    this.getTasks()
  }

  refresh () {
    this.offset = 0
    this.getTasks()
  }

  editProductDetails (product:object) {
    this.productDetails = product
  }

  get hasLoadMore (): boolean {
    return this.tasks.length < this.totalCount
  }
}
